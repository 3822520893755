<template>
<div id="app">

    <v-row>

<v-col cols="5" lg="2">
  <v-menu v-model="menu" :close-on-content-click="false">
    <v-text-field
      :value="date_debut"
      slot="activator"
      v-model="date_debut"
      prepend-icon="mdi-calendar"
      clearable label="Date debut"
     ></v-text-field>
      <v-v-date-picker v-model="date_debut" @change="menu = false" ></v-v-date-picker>
  </v-menu>

</v-col>
<v-col cols="5" lg="2">
  <v-menu>
    <v-text-field
      :value="date_fin"
      slot="activator"
      v-model="date_fin"
      prepend-icon="mdi-calendar"
      label="Date fin"
     ></v-text-field>
      <v-v-date-picker v-model="date_fin" ></v-v-date-picker>
  </v-menu>

</v-col>
<v-col>
    <v-btn color="primary" dark class="mb-2" @click="readAll">
        Recherche
    </v-btn>

</v-col>


<v-col v-if="null">
    <v-btn color="green" dark class="mb-2" @click="Actulisation()">
        Actualiser
    </v-btn>

</v-col>

    </v-row>

    <v-data-table :headers="headers" :items="list" class="elevation-1" color="primary">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>LISTE DES DEPOTS CRYPTO</v-toolbar-title>

                <v-dialog v-model="dialog_solde" max-width="400">
                  <v-card>
                      <v-toolbar flat color="blue">
                          <v-toolbar-title >Solde Adresse Crypto</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn icon dark @click="dialog_solde = false">
                              <v-icon>mdi-close</v-icon>
                          </v-btn>
                      </v-toolbar>
                      <v-card-text>
                          <v-col cols="12">
                              <v-text-field v-model="solde" label="Solde" outlined></v-text-field>
                          </v-col>

                      </v-card-text>
                      <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="green" dark @click="transfert()">Transferer</v-btn>
                          <v-spacer></v-spacer>
                      </v-card-actions>
                  </v-card>
                </v-dialog>

            </v-toolbar>
        </template>



        <template v-slot:[`item.actions`]="{ item }">
            <v-btn @click="showDialog(item)">
                <v-icon color="blue" large class="mr-2">
                    mdi-eye
                </v-icon>
            </v-btn>
        </template>

    </v-data-table>

</div>
</template>

<script>
//import api from '../../../serviceApi/apiService'
import api from '@/serviceApi/apiService'
export default {
    name: "Gestion des transactions",
    data: () => ({

        headers: [

            {
                text: "Actions",
                align: "start",
                sortable: false,
                value: "actions",
                divider: true,
                width: '1px'

            },

            {

               text: "Date de depot",
               align: "start",
               sortable: false,
               value: "deposit_server_time",
               divider: true,
               width: '100px'
           },

            {
                text: "Crypto",
                align: "start",
                sortable: false,
                value: "having_currencie",
                divider: true,
                width: '150px'
            },

            {
                text: "Network",
                align: "start",
                sortable: false,
                value: "havingNetwork",
                divider: true,
                width: '150px'
            },

            {
                text: "Adresse",
                align: "start",
                sortable: false,
                value: "having_adresse",
                divider: true,
                width: '150px'
            },


            {
                text: "Montant",
                align: "start",
                sortable: false,
                value: "having_amount",
                divider: true,
                width: '150px'
            },


        ],

        list: [],
        date_debut: null,
        date_fin: null,
        solde: 0,
        crypto: '',
        network: '',
        adresse: '',
        loading: false,
        dialog_solde: false,



    }),

    created() {
          let today = new Date();
          let dd = today.getDate();
          let mm = today.getMonth()+1; //January is 0!
          let yyyy = today.getFullYear();
          this.date_debut = yyyy+'-'+mm+'-'+dd;
          this.date_fin = yyyy+'-'+mm+'-'+dd;
        this.readAll();
    },
    methods: {
        readAll: async function () {

            const data = await api.createUpdatedata('backoffice/crypto-deposits', {
                date_debut: this.date_debut,
                date_fin: this.date_fin
            });
            this.list = data;
        },

        transfert: async function () {
          var url = '';
            switch(this.network){
              case 'BEP20':
                  if(this.crypto == 'BNB'){
                      url = 'https://easychains.io/api/v1/bsc/balance_bnb?address='+this.adresse;
                  }else{
                    url = 'https://easychains.io/api/v1/bsc/balance_bep20?address='+this.adresse+'&tokenAdress=0xe9e7cea3dedca5984780bafc599bd69add087d56';
                  }
                break;
              case 'TRC20':
                  if(this.crypto == 'TRON'){
                      url = 'https://easychains.io/api/v1/trx/balance_trx?address='+this.adresse;
                    }else{
                      url = 'https://easychains.io/api/v1/trx/balance_trc20?address='+this.adresse+'&trc20ContractAddress=TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t';
                    }
                break;
            }
            const data = await api.readAll(url);
            showAlert('Votre solde est de '+data+' '+this.crypto, 'success');
        },

        showDialog: async function (item) {
            this.dialog_solde = true;
            this.crypto = item.having_currencie;
            this.network = item.havingNetwork;
            this.adresse = item.having_adresse;
            //console.log('crypto', this.crypto+ 'network', this.network+ 'adresse', this.adresse);
            var url = '';
            switch(this.network){
              case 'BEP20':
                  if(this.crypto == 'BNB'){
                      url = 'https://easychains.io/api/v1/bsc/balance_bnb?address='+this.adresse;
                  }else{
                    url = 'https://easychains.io/api/v1/bsc/balance_bep20?address='+this.adresse+'&tokenAdress=0xe9e7cea3dedca5984780bafc599bd69add087d56';
                  }
                break;
              case 'TRC20':
                  if(this.crypto == 'TRON'){
                      url = 'https://easychains.io/api/v1/trx/balance_trx?address='+this.adresse;
                    }else{
                      url = 'https://easychains.io/api/v1/trx/balance_trc20?address='+this.adresse+'&trc20ContractAddress=TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t';
                    }
                break;
            }
            const data = await api.readAll(url);
            this.solde = data;

        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                position: 'top-end',
                icon: txticone,
                title: textmessage,
                showConfirmButton: false,
                timer: 1500
            })
        },



    },
};
</script>
